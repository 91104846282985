<template>
    <div class="dashboard">
        <div class="row align-items-center justify-content-center text-desc">
            <div class="col-md-6 text-center">
                <img src="./images/404.svg" alt="">
            </div>
            <div class="col-md-6">
                <div class="h1">ОЙ!</div>
                <div class="mb-5">Похоже, страница, которую вы ищете, была удалена ...</div>
                <router-link class="btn btn-primary text-uppercase pl-5 pr-5" :to="{ name: 'forums' }">на главную</router-link>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "not-found"
    }
</script>

<style lang="scss" scoped>
    .text-desc{
        font-size: 40px;
        line-height: 1.2;
        .h1{
            font-size: 64px;
        }
        img{
            max-width: 100%;
        }
    }
</style>